import React from 'react'
import {MenuItem} from './MenuItem'
import {RootState} from 'redux/store'
import {useSelector} from 'react-redux'
import {useIntl} from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  const isAdmin = useSelector<RootState>(({auth}: any) => auth.user?.isAdmin)

  return isAdmin ? (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU_LINK_USERS'})} to='/' />
      <MenuItem title={intl.formatMessage({id: 'MENU_LINK_SETTINGS'})} to='/settings' />
      <MenuItem title={intl.formatMessage({id: 'MENU_LINK_MODULE'})} to='/module-settings' />
      <MenuItem title={intl.formatMessage({id: 'MENU_LINKS_REPORTS'})} to='/reports' />
    </>
  ) : (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU_LINK_HOME'})} to='/' />
      <MenuItem title={intl.formatMessage({id: 'MENU_LINK_FAQ'})} to='/faq' />
      <MenuItem title={intl.formatMessage({id: 'MENU_LINK_SUPPORT'})} to='/support' />
    </>
  )
}
