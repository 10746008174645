// import {compose, applyMiddleware, createStore} from 'redux'
// import {composeWithDevTools} from '@redux-devtools/extension'
// import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage'

import rootReducer from './reducers'
import services from 'infrastructure/services'
import {configureStore} from '@reduxjs/toolkit'
import {persistReducer, persistStore} from 'redux-persist'

const {NODE_ENV} = process.env
// export const store = function configureStore() {
//   return createStore(
//     reducers,
//     NODE_ENV === 'development'
//       ? composeWithDevTools(applyMiddleware(thunk.withExtraArgument(services)))
//       : compose(applyMiddleware(thunk.withExtraArgument(services)))
//   )
// }
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
      thunk: {
        extraArgument: services,
      },
    }),
  ],
  devTools: NODE_ENV === 'development',
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
