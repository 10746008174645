/* eslint-disable */
import {ISupportDto} from 'interfaces'
import axios from 'infrastructure/services/axios/interceptor'

const baseUrl = process.env.REACT_APP_API_URL

export default {
    sendSupport: (dto: ISupportDto) => {
        return axios.post(`${baseUrl}/Support`, dto).then(res => res.data)
    },
}
