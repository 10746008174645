/* eslint-disable */
import {IGetParams} from 'interfaces'
import axios from 'infrastructure/services/axios/interceptor'

const baseUrl = process.env.REACT_APP_API_URL

export default {
    getAll: (params: IGetParams) => {
        return axios.get(`${baseUrl}/User`, {params}).then(res => res.data)
    },

    get: (id: number) => {
        return axios.get(`${baseUrl}/User/${id}`).then(res => res.data)
    },

    add: (data: FormData) => {
        return axios.post(`${baseUrl}/User`, data).then(res => res.data)
    },

    update: (id: number, data: FormData) => {
        return axios.put(`${baseUrl}/User/${id}`, data).then(res => res.data)
    },

    delete: (id: number) => {
        return axios.delete(`${baseUrl}/User/${id}`).then(res => res.data)
    },

    disableUser: (username: string) => {
        return axios.post(`${baseUrl}/User/DisableUser`, {}, {params: {username}}).then(res => res.data)
    },

    enableUser: (username: string) => {
        return axios.post(`${baseUrl}/User/EnableUser`, {}, {params: {username}}).then(res => res.data)
    },

    searchUsers: (searchText: string, pageNumber: number, pageSize: number) => {
        return axios.get(`${baseUrl}/User/GetUsersBySearch`, {
            params: {searchText, pageNumber, pageSize},
        }).then(res => res.data)
    },
}
