import React from 'react'
type Props = {
  disabled: boolean
  loading: boolean
  label: string
  loadingLabel?: string
  className?: string
}
const SubmitButton: React.FC<Props> = ({disabled, loading, label, loadingLabel, className}) => {
  const cn = ['btn btn-primary btn-sm']
  if (className) {
    cn.push(className)
  }
  return (
    <button type='submit' id='kt_sign_in_submit' className={cn.join(' ')} disabled={disabled}>
      {!loading && <span className='indicator-label'>{label}</span>}
      {loading && (
        <span className='indicator-progress' style={{display: 'block'}}>
          {loadingLabel || 'Loading...'}
          <span className='spinner-border spinner-border-sm align-middle ms-2' />
        </span>
      )}
    </button>
  )
}
export {SubmitButton}
