// @ts-nocheck

import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import {Routes} from './routing/Routes'
import {ToastContainer} from 'react-toastify'
import {useIntl} from 'react-intl'
import {IntlShape} from 'react-intl/src/types'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <IntlGlobalProvider>
            <LayoutProvider>
              <AuthInit>
                <Routes />
              </AuthInit>
            </LayoutProvider>
          </IntlGlobalProvider>
        </I18nProvider>
        <ToastContainer position='top-right' autoClose={4000} theme='colored' />
      </BrowserRouter>
    </Suspense>
  )
}

export {App}

// 'intl' service singleton reference
let intl: IntlShape

export function IntlGlobalProvider({children}: any) {
  intl = useIntl() // Keep the 'intl' service reference
  return children
}

// Getter function to expose the read-only 'intl' service
export function appIntl() {
  return intl
}
