import {toast} from 'react-toastify'
import {Dispatch} from 'redux'
import {
    IAllStates,
    IGetParams,
    IReportState,
    IServices,
    ReportModel,
    SpeciesModel,
} from 'interfaces'
import {errorMessageThrower} from 'utils/ErrorThrower'
import {appIntl} from 'app/App'
import axios from 'axios'

export const SET_REPORT = '[report] set'

const setReport = (report: IReportState) => ({type: SET_REPORT, payload: report})

export const getReports = (params: IGetParams) => {
    return async (
        dispatch: Dispatch,
        _getState: () => IAllStates,
        {api}: IServices
    ): Promise<ReportModel[]> => {
        try {
            const report = await api.report.getReports(params)
            dispatch(setReport(report))
            return report.data
        } catch (error) {
            const _section = appIntl().formatMessage({id: 'REDUX.REPORTS'})
            const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.FAIL_ALL'}, {section: _section})
            if (!axios.isCancel(error)) toast.error(_message)
            return errorMessageThrower(error)
        }
    }
}
export const getReport = (id: number) => {
    return async (
        dispatch: Dispatch,
        _getState: () => IAllStates,
        {api}: IServices
    ): Promise<ReportModel> => {
        try {
            return await api.report.getReport(id)
        } catch (error) {
            const _section = appIntl().formatMessage({id: 'REDUX.REPORT'})
            const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.FAIL_ONE'}, {section: _section})
            if (!axios.isCancel(error)) toast.error(_message)
            return errorMessageThrower(error)
        }
    }
}

export const createReport = (data: ReportModel) => {
    return async (dispatch: any, getState: () => IAllStates, {api}: IServices): Promise<void> => {
        try {
            const formData = new FormData()
            formData.append('name', data.name)
            formData.append('options', data.options)
            formData.append('moduleId', data.moduleId.toString())
            formData.append('speciesId', data.speciesId.toString())
            formData.append('template', data.template)
            // TODO - what is username
            formData.append('username', getState().auth.user?.username ?? '')

            await api.report.addReport(formData)
            await refreshData(dispatch, getState)

            const _section = appIntl().formatMessage({id: 'REDUX.REPORT'})
            const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.CREATED'}, {section: _section})
            toast.success(_message)
        } catch (error: any) {
            return errorMessageThrower(error)
        }
    }
}

export const updateReport = (data: ReportModel) => {
    return async (dispatch: any, getState: () => IAllStates, {api}: IServices): Promise<void> => {
        try {
            const formData = new FormData()
            formData.append('id', data.id!.toString())
            formData.append('name', data.name)
            formData.append('options', data.options)
            formData.append('moduleId', data.moduleId.toString())
            formData.append('speciesId', data.speciesId.toString())
            if (data.template) formData.append('template', data.template)
            formData.append('username', getState().auth.user?.username ?? '')

            await api.report.updateReport(data.id!, formData)
            await refreshData(dispatch, getState)

            const _section = appIntl().formatMessage({id: 'REDUX.REPORT'})
            const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.UPDATED'}, {section: _section})
            toast.success(_message)
        } catch (error: any) {
            return errorMessageThrower(error)
        }
    }
}

export const deleteReport = (id: number) => {
    return async (dispatch: any, getState: () => IAllStates, {api}: IServices): Promise<void> => {
        const _section = appIntl().formatMessage({id: 'REDUX.REPORT'})
        try {
            await api.report.deleteReport(id)
            await refreshData(dispatch, getState)

            const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.DELETED'}, {section: _section})
            toast.success(_message)
        } catch (error) {
            const _message = appIntl().formatMessage(
                {id: 'REDUX.GENERAL.DELETE.FAILED'},
                {section: _section}
            )
            if (!axios.isCancel(error)) toast.error(_message)
        }
    }
}

export const getJSONFromTemplate = (data: File) => {
    return async (
        dispatch: Dispatch,
        _getState: () => IAllStates,
        {api}: IServices
    ): Promise<string> => {
        try {
            const formData = new FormData()
            formData.append('template', data)

            return await api.report.getJSONFromTemplate(formData)
        } catch (error) {
            const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.JSON_FROM_TEMPLATE'})
            if (!axios.isCancel(error)) toast.error(_message)
            return errorMessageThrower(error)
        }
    }
}
export const getSpecies = () => {
    return async (
        dispatch: Dispatch,
        _getState: () => IAllStates,
        {api}: IServices
    ): Promise<SpeciesModel[]> => {
        try {
            return await api.report.getSpecies()
        } catch (error) {
            const _section = appIntl().formatMessage({id: 'REDUX.SPECIES'})
            const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.FAIL_ALL'}, {section: _section})
            if (!axios.isCancel(error)) toast.error(_message)
            return []
        }
    }
}

export const createNotesDecisions = (GFIFile: any, isProfit: boolean, moduleId: number) => {
    return async (
        dispatch: Dispatch,
        getState: () => IAllStates,
        {api}: IServices
    ): Promise<void> => {
        try {
            const userId = getState().auth.user?.userId

            var formData = new FormData()
            formData.append('userId', userId!.toString())
            formData.append('moduleId', moduleId.toString())
            formData.append('isProfit', isProfit.toString())
            formData.append('GFIFile', GFIFile)

            return await api.report.createNoteDecisions(formData)
        } catch (error: any) {
            return errorMessageThrower(error)
        }
    }
}

export const downloadNotesDecisions = (moduleId?: number) => {
    return async (dispatch: Dispatch, getState: () => IAllStates, {api}: IServices): Promise<any> => {
        try {
            const userId = getState().auth.user?.userId
            var res = await api.report.downloadNoteDecisions(userId!, moduleId!)
            console.log(res);
            return res;
        } catch (error) {
            return errorMessageThrower(error)
        }
    }
}

const refreshData = async (dispatch: any, getState: () => IAllStates) => {
    const {pageNumber, pageSize} = getState().report
    await dispatch(getReports({PageNumber: pageNumber, PageSize: pageSize}))
}
