import axios from 'axios'

export const errorMessageThrower = (error: any) => {
  let message
  if (axios.isAxiosError(error) && error.response) {
    message = error?.response?.data?.message ?? error?.response?.statusText
  } else message = String(error)

  throw new Error(message)
}

export const errorMessageReturner = (error: any) => {
  let message
  if (axios.isAxiosError(error) && error.response) {
    message = error?.response?.data?.message ?? error?.response?.statusText
  } else message = String(error)

  return message
}
