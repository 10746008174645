import {FC, useRef, useEffect, useState} from 'react'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {LayoutSplashScreen} from '_metronic/layout/core'
import {RootState} from 'redux/store'
import {logout} from 'redux/actions/auth-action'
// import * as auth from './AuthRedux'
// import {getUserByToken} from './AuthCRUD'
// import {RootState} from 'setup'

// const mapState = (state: RootState) => ({auth: state.auth})
// const connector = connect(mapState, auth.actions)
// type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC = (props:any) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const token = useSelector<RootState>(({auth}: any) => auth.user?.token, shallowEqual)

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          //       const {data: user} = await getUserByToken(token as string)
          //       dispatch(props.fulfillUser(user))
          // dispatch(autoLogin())
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          dispatch(logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }
    if (token) {
      // refresh the user token
      requestUser()
    } else {
      // dispatch(logout())
      setShowSplashScreen(false)
    }
  }, [dispatch, token])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props?.children}</>
}

export default AuthInit
// export default connector(AuthInit)
