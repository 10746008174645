import React, { InputHTMLAttributes } from "react";
import clsx from "clsx";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: string | null | boolean;
  touched?: boolean;
  label?: string;
  type?: string;
  placeholder?: string;
  rowInput?: boolean;
}

const Input: React.FC<Props> = ({
                                  error,
                                  touched,
                                  label,
                                  type = "text",
                                  placeholder = "",
                                  rowInput,
                                  ...props
                                }) => {

  const renderInput = () => (
    <input
      type={type}
      placeholder={placeholder}
      className={clsx(
        "form-control form-control-solid form-control-sm",
        { "is-invalid": touched && error },
        {
          "is-valid": touched && !error
        }
      )}
      value={props.value ?? ""}
      {...props}
    />
  );


  const renderError = () => (
    <div className="fv-plugins-message-container">
      <div className="fv-help-block text-danger">
        <span role="alert">{error}</span>
      </div>
    </div>
  );

  if (rowInput) {
    return (
      <div className="row">
        <div className="col-md-3 col-sm-12">
          <label className="col-form-label fs-6 fw-bolder text-dark">{label}</label>
        </div>
        <div className="col-md-9 col-sm-12">
          {renderInput()}
          {touched && error && renderError()}
        </div>
      </div>
    );
  }
  return (
    <div className="w-full">
      {label && <label className="col-form-label fw-bolder text-dark">{label}</label>}
      {renderInput()}
      {touched && error && renderError()}
    </div>
  );
};

export { Input };
