import {toast} from 'react-toastify'
import {Dispatch} from 'redux'
import {
  IAllStates,
  IGetParams,
  IServices,
  IBannerResponse,
  IFaqResponse,
  FaqModel,
  BannerModel,
} from 'interfaces'
import {errorMessageThrower} from 'utils/ErrorThrower'
import {appIntl} from '../../app/App'
import axios from 'axios'

export const SET_BANNER = '[settings] banner'
export const SET_FAQ = '[settings] faq'

const setBanner = (banner: IBannerResponse) => ({type: SET_BANNER, payload: banner})
const setFaq = (faq: IFaqResponse) => ({type: SET_FAQ, payload: faq})

export const getFaqs = (params: IGetParams) => {
  return async (
    dispatch: Dispatch,
    _getState: () => IAllStates,
    {api}: IServices
  ): Promise<FaqModel[]> => {
    try {
      const faq = await api.settings.getFaqs(params)
      dispatch(setFaq(faq))
      return faq.data
    } catch (error) {
      const _section = appIntl().formatMessage({id: 'REDUX.FAQS'})
      const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.FAIL_ALL'}, {section: _section})
      if (!axios.isCancel(error)) toast.error(_message)
      return errorMessageThrower(error)
    }
  }
}

export const createFaq = (data: FaqModel) => {
  return async (dispatch: any, getState: () => IAllStates, {api}: IServices): Promise<void> => {
    try {
      await api.settings.addFaq(data)
      await refreshFaqData(dispatch, getState)

      const _section = appIntl().formatMessage({id: 'REDUX.FAQ'})
      const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.CREATED'}, {section: _section})
      toast.success(_message)
    } catch (error) {
      return errorMessageThrower(error)
    }
  }
}

export const updateFaq = (data: FaqModel) => {
  return async (
    dispatch: Dispatch,
    getState: () => IAllStates,
    {api}: IServices
  ): Promise<void> => {
    try {
      await api.settings.updateFaq(data)
      await refreshFaqData(dispatch, getState)

      const _section = appIntl().formatMessage({id: 'REDUX.FAQ'})
      const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.UPDATED'}, {section: _section})
      toast.success(_message)
    } catch (error) {
      return errorMessageThrower(error)
    }
  }
}

export const deleteFaq = (id: number) => {
  return async (
    dispatch: Dispatch,
    getState: () => IAllStates,
    {api}: IServices
  ): Promise<void> => {
    const _section = appIntl().formatMessage({id: 'REDUX.FAQ'})
    try {
      await api.settings.deleteFaq(id)
      await refreshFaqData(dispatch, getState)

      const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.DELETED'}, {section: _section})
      toast.success(_message)
    } catch (error) {
      const _message = appIntl().formatMessage(
        {id: 'REDUX.GENERAL.DELETE.FAILED'},
        {section: _section}
      )
      if (!axios.isCancel(error)) toast.error(_message)
    }
  }
}

export const reorderFaq = (id: number, newOrderIndex: number) => {
  return async (
    dispatch: Dispatch,
    _getState: () => IAllStates,
    {api}: IServices
  ): Promise<void> => {
    try {
      await api.settings.reorderFaq(id, newOrderIndex)
      // dispatch(setFaq(faqs))
    } catch (error) {
      const _message = appIntl().formatMessage({id: 'REDUX.FAQ.REORDER.FAILED'})
      if (!axios.isCancel(error)) toast.error(_message)
      return errorMessageThrower(error)
    }
  }
}

export const getBanners = (params: IGetParams, hideError = false) => {
  return async (
    dispatch: Dispatch,
    _getState: () => IAllStates,
    {api}: IServices
  ): Promise<BannerModel[]> => {
    try {
      const banner = await api.settings.getBanners(params)
      dispatch(setBanner(banner))
      return banner.data
    } catch (error) {
      dispatch(setBanner({data: [], pageNumber: 1, pageSize: 3, totalRecordCount: 0}))
      if (!hideError) {
        const _section = appIntl().formatMessage({id: 'REDUX.BANNERS'})
        const _message = appIntl().formatMessage(
          {id: 'REDUX.GENERAL.FAIL_ALL'},
          {section: _section}
        )
        if (!axios.isCancel(error)) toast.error(_message)
      }
      return errorMessageThrower(error)
    }
  }
}

export const createBanner = (data: BannerModel) => {
  return async (dispatch: any, getState: () => IAllStates, {api}: IServices): Promise<void> => {
    try {
      const formData = new FormData()
      formData.append('image', data.bannerPath!)
      formData.append('link', data.link)
      formData.append('description', data.description)
      formData.append('title', data.title)

      await api.settings.addBanner(formData)
      await refreshBannerData(dispatch, getState)

      const _section = appIntl().formatMessage({id: 'REDUX.MODULES'})
      const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.CREATED'}, {section: _section})
      toast.success(_message)
    } catch (error: any) {
      return errorMessageThrower(error)
    }
  }
}

export const updateBanner = (data: BannerModel) => {
  return async (dispatch: any, getState: () => IAllStates, {api}: IServices): Promise<void> => {
    try {
      const formData = new FormData()
      formData.append('id', data.id!.toString())
      if (data.bannerPath) formData.append('image', data.bannerPath)
      formData.append('link', data.link)
      formData.append('description', data.description)
      formData.append('title', data.title)

      await api.settings.updateBanner(data.id!, formData)
      await refreshBannerData(dispatch, getState)

      const _section = appIntl().formatMessage({id: 'REDUX.BANNER'})
      const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.UPDATED'}, {section: _section})
      toast.success(_message)
    } catch (error) {
      return errorMessageThrower(error)
    }
  }
}

export const deleteBanner = (id: number) => {
  return async (dispatch: any, getState: () => IAllStates, {api}: IServices): Promise<void> => {
    const _section = appIntl().formatMessage({id: 'REDUX.BANNER'})
    try {
      await api.settings.deleteBanner(id)
      await refreshBannerData(dispatch, getState)

      const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.DELETED'}, {section: _section})
      toast.success(_message)
    } catch (error) {
      const _message = appIntl().formatMessage(
        {id: 'REDUX.GENERAL.DELETE.FAILED'},
        {section: _section}
      )
      if (!axios.isCancel(error)) toast.error(_message)
    }
  }
}

const refreshFaqData = async (dispatch: any, getState: () => IAllStates) => {
  const {pageNumber, pageSize} = getState().settings.faq
  await dispatch(getFaqs({PageNumber: pageNumber, PageSize: pageSize}))
}

const refreshBannerData = async (dispatch: any, getState: () => IAllStates) => {
  const {pageNumber, pageSize} = getState().settings.banner
  await dispatch(getBanners({PageNumber: pageNumber, PageSize: pageSize}))
}
