import {IModuleAction, IModuleState} from 'interfaces'
import {SET_MODULE} from 'redux/actions/module-action'

const initialState: IModuleState = {
  data: [],
  totalRecordCount: 0,
  pageNumber: 1,
  pageSize: 10,
}

const reducer = (state = initialState, action: IModuleAction) => {
  switch (action.type) {
    case SET_MODULE:
      return action.payload

    default:
      return state
  }
}

export default reducer
