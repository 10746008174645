/* eslint-disable */
import {ILoginDto, IPasswordChangeDto} from 'interfaces'
import axios from 'infrastructure/services/axios/interceptor'

const baseUrl = process.env.REACT_APP_API_URL

export default {
    login: (credentials: ILoginDto) => {
        return axios.post(`${baseUrl}/Auth/Authenticate`, credentials).then(res => res.data)
    },
    firstLoginDecrypt: (dto: IPasswordChangeDto) => {
        return axios.post(`${baseUrl}/Auth/DecryptText`, dto).then(res => res.data)
    },
    firstLogin: (dto: IPasswordChangeDto) => {
        return axios.post(`${baseUrl}/Auth/GeneratePassword`, dto).then(res => res.data)
    },
    autoLogin: (refreshToken: string) => {
        return axios.post(`/Auth/AutoLogin`, {headers: {refreshToken}}).then(res => res.data)
    },
    logout: (refreshToken: string) => {
        return axios.post(`/Auth/Logout`, {refreshToken}).then(res => res.data)
    },
    forgotPassword: (username: string) => {
        return axios.post(`/Auth/ForgetPassword`, {username}).then(res => res.data)
    },
    setPassword: (dto: IPasswordChangeDto) => {
        return axios.post(`/Auth/SetPassword`, dto).then(res => res.data)
    },
    changePassword: (username: string, currentPassword: string, newPassword: string) => {
        return axios.post(`/Auth/ChangePassword`, {username, currentPassword, newPassword}).then(res => res.data)
    },
}
