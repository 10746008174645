import { ReactNode } from "react";

type Props = {
  active: boolean;
  children: ReactNode
}

export function FallbackOverlay({ active = false, children }: Props) {
  return (
    <>
      {children}

      {active && <div id="overlay">
        <div className="d-flex align-items-center justify-content-center flex-column h-100 w-100">
          <div className="spinner-border text-primary" role="status" />
        </div>
      </div>}
    </>

  );
}
