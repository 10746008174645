import {FC} from 'react'
import {useIntl} from 'react-intl'

const Error404: FC = () => {
  const intl = useIntl()
  return (
    <>
      <h1 className='fw-bolder text-gray-700 mb-5 mt-10 mt-lg-0' style={{fontSize: '8rem'}}>
        {intl.formatMessage({id: 'Page404Heading'})}
      </h1>
      <div className='d-flex justify-content-center'>
        <p className='text-center w-lg-50 fs-2x text-gray-700 mb-10'>
          {intl.formatMessage({id: 'Page404Desc'})}
        </p>
      </div>
    </>
  )
}

export {Error404}
