import {Dispatch} from 'redux'
import {IAllStates, IGetParams, IModuleState, IServices, ModuleModel} from 'interfaces'
import {toast} from 'react-toastify'
import {errorMessageThrower} from 'utils/ErrorThrower'
import {appIntl} from 'app/App'
import axios from 'axios'

export const SET_MODULE = '[module] set'

const setModules = (module: IModuleState) => ({type: SET_MODULE, payload: module})

export const getModules = (params: IGetParams) => {
    return async (
        dispatch: Dispatch,
        _getState: () => IAllStates,
        {api}: IServices
    ): Promise<ModuleModel[]> => {
        try {
            const module = await api.module.getModules(params)
            dispatch(setModules(module))
            return module.data
        } catch (error) {
            const _section = appIntl().formatMessage({id: 'REDUX.MODULES'})
            const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.FAIL_ALL'}, {section: _section})
            if (!axios.isCancel(error)) toast.error(_message)
            return errorMessageThrower(error)
        }
    }
}

export const getModulesForUser = () => {
    return async (
        dispatch: Dispatch,
        _getState: () => IAllStates,
        {api}: IServices
    ): Promise<ModuleModel[]> => {
        try {
            return await api.module.getModulesForUser()
        } catch (error) {
            const _section = appIntl().formatMessage({id: 'REDUX.MODULE'})
            const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.FAIL_ONE'}, {section: _section})
            if (!axios.isCancel(error)) toast.error(_message)
            return errorMessageThrower(error)
        }
    }
}

export const getModulesForReportGeneration = () => {
    return async (
        dispatch: Dispatch,
        _getState: () => IAllStates,
        {api}: IServices
    ): Promise<ModuleModel[]> => {
        const _section = appIntl().formatMessage({id: 'REDUX.MODULE'})
        const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.FAIL_ONE'}, {section: _section})

        try {
            const userId = _getState().auth.user?.userId
            if (userId) {
                const modules = await api.module.getModulesForReportGeneration(userId)
                dispatch(
                    setModules({
                        data: modules,
                        totalRecordCount: 100,
                        pageNumber: 1,
                        pageSize: 100,
                    })
                )
                return modules
            } else {
                return errorMessageThrower(_message)
            }
        } catch (error) {
            console.error(error);
            if (!axios.isCancel(error)) toast.error(_message)
            return errorMessageThrower(error)
        }
    }
}

export const getModule = (id: number) => {
    return async (
        dispatch: Dispatch,
        _getState: () => IAllStates,
        {api}: IServices
    ): Promise<ModuleModel> => {
        try {
            const userId = _getState().auth.user?.userId
            return await api.module.getModule(id, userId!)
        } catch (error) {
            const _section = appIntl().formatMessage({id: 'REDUX.MODULE'})
            const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.FAIL_ONE'}, {section: _section})
            if (!axios.isCancel(error)) toast.error(_message)
            return errorMessageThrower(error)
        }
    }
}

export const createModule = (data: ModuleModel) => {
    return async (dispatch: any, getState: () => IAllStates, {api}: IServices): Promise<void> => {
        try {
            const formData = new FormData()
            formData.append('title', data.title)
            formData.append('isNotesType', data.isNotesType.toString())
            formData.append('isDecisionsType', data.isDecisionsType.toString())
            formData.append('isProfitType', data.isProfitType.toString())
            formData.append('isLossType', data.isLossType.toString())
            formData.append('description', data.description)
            formData.append('image', data.imagePath)
            // TODO -
            // disabled?: boolean;

            await api.module.addModule(formData)
            await refreshModuleData(dispatch, getState)

            const _section = appIntl().formatMessage({id: 'REDUX.MODULES'})
            const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.CREATED'}, {section: _section})
            toast.success(_message)
        } catch (error: any) {
            return errorMessageThrower(error)
        }
    }
}

export const updateModule = (data: ModuleModel) => {
    return async (
        dispatch: Dispatch,
        getState: () => IAllStates,
        {api}: IServices
    ): Promise<void> => {
        try {
            const formData = new FormData()
            formData.append('id', data.id!.toString())
            formData.append('title', data.title)
            formData.append('isNotesType', data.isNotesType.toString())
            formData.append('isDecisionsType', data.isDecisionsType.toString())
            formData.append('isProfitType', data.isProfitType.toString())
            formData.append('isLossType', data.isLossType.toString())
            formData.append('description', data.description)
            formData.append('image', data.imagePath)

            await api.module.updateModule(data.id!, formData)
            await refreshModuleData(dispatch, getState)

            const _section = appIntl().formatMessage({id: 'REDUX.MODULES'})
            const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.UPDATED'}, {section: _section})
            toast.success(_message)
        } catch (error) {
            return errorMessageThrower(error)
        }
    }
}

export const deleteModule = (id: number) => {
    return async (dispatch: any, getState: () => IAllStates, {api}: IServices): Promise<void> => {
        const _section = appIntl().formatMessage({id: 'REDUX.MODULES'})
        try {
            await api.module.deleteModule(id)
            await refreshModuleData(dispatch, getState)

            const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.DELETED'}, {section: _section})
            toast.success(_message)
        } catch (error) {
            const _message = appIntl().formatMessage(
                {id: 'REDUX.GENERAL.DELETE.FAILED'},
                {section: _section}
            )
            if (!axios.isCancel(error)) toast.error(_message)
        }
    }
}

const refreshModuleData = async (dispatch: any, getState: () => IAllStates) => {
    const {pageNumber, pageSize} = getState().module
    await dispatch(getModules({PageNumber: pageNumber, PageSize: pageSize}))
}
