import {RootState} from 'redux/store'
import React, {Suspense, lazy} from 'react'
import {useSelector} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '_metronic/partials'

export function PrivateRoutes() {
  const isAdmin = useSelector<RootState>(({auth}: any) => auth.user?.isAdmin)

  const SupportPage = lazy(() => import('../modules/support/SupportPage'))
  const Faq = lazy(() => import('../modules/faq/FaqPage'))
  const Module = lazy(() => import('../modules/module/ModulePage'))
  const Home = lazy(() => import('../modules/home/HomePage'))
  const Profile = lazy(() => import('../modules/profile/ProfilePage'))
  const Users = lazy(() => import('../modules/users/UsersPage'))
  const Settings = lazy(() => import('../modules/settings/SettingsPage'))
  const AdminModule = lazy(() => import('../modules/adminModule/AdminModulePage'))
  const ReportsPage = lazy(() => import('../modules/reports/ReportsPage'))

  const userRoutes = [
    {path: '/support', component: SupportPage},
    {path: '/faq', component: Faq},
    {path: '/module/:id/:title', component: Module},
    {path: '/', component: Home},
  ]
  const adminRoutes = [
    {path: '/', component: Users},
    {path: '/settings', component: Settings},
    {path: '/module-settings', component: AdminModule},
    {path: '/reports', component: ReportsPage},
  ]
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route exact path='/profile' component={Profile} />
        {isAdmin
          ? adminRoutes.map((r) => (
              <Route exact path={r.path} component={r.component} key={r.path} />
            ))
          : userRoutes.map((r) => (
              <Route exact path={r.path} component={r.component} key={r.path} />
            ))}
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
