export function FallbackView() {
  return (
    <div className='bg-white'>
      <div className='d-flex align-items-center justify-content-center flex-column h-200px w-100'>
        <div className='spinner-border text-primary' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    </div>
  )
}
