import React from 'react'

type Props = {
  children?:any,
  success?: boolean
  error?: boolean
  message: string
}
const Alert: React.FC<Props> = ({children, error, success, message}) => {
  const cn = ['mb-5 alert mt-5']
  if (success) {
    cn.push('alert-success')
  } else if (error) {
    cn.push('alert-danger')
  }
  return (
    <>
      <div className={cn.join(' ')}>
        <div className='alert-text font-weight-bold'>{message}</div>
      </div>
    </>
  )
}
export {Alert}
