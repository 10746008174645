import {AuthUserModel} from 'interfaces'

class TokenService {
  getLocalRefreshToken = () => {
    const user = JSON.parse(window.localStorage.getItem('user') ?? 'null')
    return user?.refreshToken
  }

  getLocalAccessToken = (): string => {
    const user = JSON.parse(window.localStorage.getItem('user') ?? 'null')
    return user?.token
  }

  updateLocalAccessToken = (token: string, refresh: string) => {
    const user = JSON.parse(window.localStorage.getItem('user') ?? 'null')
    user.token = token
    user.refreshToken = refresh
    window.localStorage.setItem('user', JSON.stringify(user))
  }

  getUser = (): AuthUserModel => {
    return JSON.parse(window.localStorage.getItem('user') ?? 'null')
  }

  setUser = (user: AuthUserModel) => window.localStorage.setItem('user', JSON.stringify(user))

  removeUser = () => window.localStorage.removeItem('user')
}

export default new TokenService()
