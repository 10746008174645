import {Dispatch} from 'redux'
import {
  AuthUserModel,
  IAllStates,
  IPasswordChangeDto,
  ILoginDto,
  IServices,
  IFirstLoginDecryptDto,
} from 'interfaces'
import {errorMessageReturner, errorMessageThrower} from 'utils/ErrorThrower'
import {appIntl} from 'app/App'
import {toast} from 'react-toastify'
import TokenService from 'infrastructure/services/token/index'

export const SET_AUTH = '[auth] set'

export const setMe = (me: AuthUserModel | null) => ({type: SET_AUTH, payload: me})

export const login = (params: ILoginDto) => {
  return async (
    dispatch: Dispatch,
    _getState: () => IAllStates,
    {api, token}: IServices
  ): Promise<boolean> => {
    try {
      let user = await api.auth.login(params)

      if (!user || !user?.token || !user?.refreshToken || user?.isNotAllowed) {
        // toast.error("Login failed");
        throw appIntl().formatMessage({id: 'REDUX.AUTH.INVALID_CREDENTIALS'})
      } else if (user?.isLockedOut === true) {
        // toast.error("Login failed");
        throw appIntl().formatMessage({id: 'REDUX.AUTH.INACTIVE'})
      }

      user = {...user, refreshToken: user?.refreshToken, isAdmin: user.userRole === 'Admin'}
      // token.setUser(user)
      // // Reflect.deleteProperty(user, 'token')
      // // Reflect.deleteProperty(user, 'refreshToken')
      dispatch(setMe(user))
      TokenService.setUser(user)

      return true
    } catch (error) {
      // toast.error(`Failed to login \n ${error}`);
      return errorMessageThrower(error)
    }
  }
}

export const firstLogin = (params: IPasswordChangeDto) => {
  return async (
    dispatch: Dispatch,
    _getState: () => IAllStates,
    {api}: IServices
  ): Promise<boolean> => {
    try {
      const response = await api.auth.firstLogin(params)
      toast.success(response?.message)
      return true
    } catch (error) {
      toast.error(errorMessageReturner(error))
      return errorMessageThrower(error)
    }
  }
}

export const firstLoginDecrypt = (params: IFirstLoginDecryptDto) => {
  return async (
    dispatch: Dispatch,
    _getState: () => IAllStates,
    {api}: IServices
  ): Promise<any> => {
    try {
      const response = await api.auth.firstLoginDecrypt(params)
      // toast.success(response?.message)
      return response
    } catch (error) {
      // toast.error(`Failed to login \n ${error}`);
      return errorMessageThrower(error)
    }
  }
}

// export const autoLogin = () => {
//   return async (
//     dispatch: Dispatch,
//     _getState: () => IAllStates,
//     {api}: IServices
//   ): Promise<boolean> => {
//     try {
//       // TODO - comment this till the token refresh fixes dispatch(setMe(null))
//
//       let user = await api.auth.autoLogin()
//
//       user = {...user, isAdmin: user.userRole === 'Admin'}
//       Reflect.deleteProperty(user, 'accessToken')
//       Reflect.deleteProperty(user, 'refreshToken')
//       // TODO - comment this till the token refresh fixes dispatch(setMe(user))
//       return true
//     } catch (error) {
//       return false
//       // return errorMessageThrower(error)
//     }
//   }
// }

export const logout = () => {
  return async (dispatch: Dispatch, getState: () => IAllStates, {api, token}: IServices) => {
    try {
      // await api.auth.logout(token.getLocalRefreshToken())
      token.removeUser()
      dispatch(setMe(null))
      return true
    } catch (error) {
      dispatch(setMe(null))
      return false
      // toast.error("Logging out has been failed");
      // return errorMessageThrower(error)
    }
  }
}

export const forgotPassword = (email: string) => {
  return async (dispatch: Dispatch, getState: () => IAllStates, {api}: IServices) => {
    try {
      await api.auth.forgotPassword(email)
      return true
    } catch (error) {
      // toast.error('Logging out has been failed');
      return errorMessageThrower(error)
    }
  }
}

export const setPassword = (params: IPasswordChangeDto) => {
  return async (
    dispatch: Dispatch,
    _getState: () => IAllStates,
    {api}: IServices
  ): Promise<boolean> => {
    try {
      const response = await api.auth.setPassword(params)
      toast.success(response?.message)
      return true
    } catch (error) {
      // toast.error(`Failed to login \n ${error}`);
      return errorMessageThrower(error)
    }
  }
}

export const changePassword = (oldPassword: string, newPassword: string) => {
  return async (dispatch: Dispatch, getState: () => IAllStates, {api}: IServices) => {
    try {
      const user = getState().auth.user
      await api.auth.changePassword(user?.username ?? '', oldPassword, newPassword)
      const _section = appIntl().formatMessage({id: 'PASSWORD'})
      const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.UPDATED'}, {section: _section})
      toast.success(_message)
    } catch (error) {
      return errorMessageThrower(error)
    }
  }
}
