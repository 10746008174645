/* eslint-disable */
import {FaqModel, IGetParams} from "interfaces";
import axios from "infrastructure/services/axios/interceptor";

const baseUrl = process.env.REACT_APP_API_URL;

export default {
    getBanners: (params: IGetParams) => {
        return axios.get(`${baseUrl}/Banner`, {params}).then(res => res.data);
    },

    getFaqs: (params: IGetParams) => {
        return axios.get(`${baseUrl}/FAQ`, {params}).then(res => res.data);
    },

    getBanner: (id: number) => {
        return axios.get(`${baseUrl}/Banner/${id}`).then(res => res.data);
    },

    getFaq: (id: number) => {
        return axios.get(`${baseUrl}/FAQ/${id}`).then(res => res.data);
    },

    addBanner: (data: FormData) => {
        return axios.post(`${baseUrl}/Banner`, data).then(res => res.data);
    },

    addFaq: (data: FaqModel) => {
        return axios.post(`${baseUrl}/FAQ`, data).then(res => res.data);
    },

    updateBanner: (id: number, data: FormData) => {
        return axios.put(`${baseUrl}/Banner/${id}`, data).then(res => res.data);
    },

    updateFaq: (data: FaqModel) => {
        return axios.put(`${baseUrl}/FAQ/${data.id}`, data).then(res => res.data);
    },

    deleteBanner: (id: number) => {
        return axios.delete(`${baseUrl}/Banner/${id}`).then(res => res.data);
    },

    deleteFaq: (id: number) => {
        return axios.delete(`${baseUrl}/FAQ/${id}`).then(res => res.data);
    },

    reorderFaq: (id: number, newOrderIndex: number) => {
        return axios.post(`${baseUrl}/FAQ/UpdateFAQOrderIndex`, {id, newOrderIndex}).then(res => res.data);
    }
};
