/* eslint-disable */
import {IGetParams} from 'interfaces'
import axios from 'infrastructure/services/axios/interceptor'

const baseUrl = process.env.REACT_APP_API_URL

export default {
    getModules: (params: IGetParams) => {
        return axios.get(`${baseUrl}/Module`, {params}).then(res => res.data)
    },

    getModulesForUser: () => {
        return axios.get(`${baseUrl}/Module/GetModulesForUser`).then(res => res.data)
    },

    getModulesForReportGeneration: (userId: number) => {
        return axios.get(`${baseUrl}/Module/GetModulesForReportGeneration?UserId=${userId}`).then(res => res.data)
    },

    getModule: (id: number, userId: number) => {
        return axios.get(`${baseUrl}/Module/${id}?userId=${userId}`).then(res => res.data)
    },

    addModule: (data: FormData) => {
        return axios.post(`${baseUrl}/Module`, data).then(res => res.data)
    },

    updateModule: (id: number, data: FormData) => {
        return axios.put(`${baseUrl}/Module/${id}`, data).then(res => res.data)
    },

    deleteModule: (id: number) => {
        return axios.delete(`${baseUrl}/Module/${id}`).then(res => res.data)
    },
}
