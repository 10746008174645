import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import {useDispatch} from 'react-redux'
import {firstLogin, firstLoginDecrypt} from 'redux/actions/auth-action'
import {Action, ThunkDispatch} from '@reduxjs/toolkit'
import {useIntl} from 'react-intl'
import {Input} from '_metronic/partials/input/Input'
import clsx from 'clsx'
import {FallbackOverlay} from '_metronic/partials/content/fallback-view/FallbackOverlay'

function useQuery() {
  const {search} = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export function FirstLogin() {
  const intl = useIntl()
  const query = useQuery()
  const history = useHistory()
  const dispatch = useDispatch<ThunkDispatch<any, any, Action>>()

  const [loading, setLoading] = useState<boolean>(false)

  const forgotPasswordSchema = Yup.object().shape({
    username: Yup.string()
      .email(
        intl.formatMessage({id: 'VALIDATIONS.INVALID'}, {field: intl.formatMessage({id: 'EMAIL'})})
      )
      .min(3, intl.formatMessage({id: 'VALIDATIONS.MIN_CHARACTERS'}, {count: 3}))
      .max(50, intl.formatMessage({id: 'VALIDATIONS.MAX_CHARACTERS'}, {count: 50}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATIONS.IS_REQUIRED'},
          {field: intl.formatMessage({id: 'EMAIL'})}
        )
      ),
    password: Yup.string()
      .min(6, intl.formatMessage({id: 'VALIDATIONS.MIN_CHARACTERS'}, {count: 6}))
      .max(50, intl.formatMessage({id: 'VALIDATIONS.MAX_CHARACTERS'}, {count: 50}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATIONS.IS_REQUIRED'},
          {field: intl.formatMessage({id: 'PASSWORD'})}
        )
      ),
    confirmPassword: Yup.string()
      .min(6, intl.formatMessage({id: 'VALIDATIONS.MIN_CHARACTERS'}, {count: 6}))
      .max(50, intl.formatMessage({id: 'VALIDATIONS.MAX_CHARACTERS'}, {count: 50}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATIONS.IS_REQUIRED'},
          {field: intl.formatMessage({id: 'AUTH.CONFIRM_PASSWORD'})}
        )
      )
      .when('password', {
        is: (val: string) => !!(val && val.length > 0),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          intl.formatMessage({id: 'AUTH.PASSWORDS_MATCH'})
        ),
      }),
  })

  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues: {
      username: '',
      token: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      await dispatch(firstLogin(values))
        .then((res) => {
          history.push('/auth/login')
        })
        .catch((error: any) => {
          setStatus(error.message)
          setSubmitting(false)
        })
      setLoading(false)
    },
  })

  useEffect(() => {
    if (query.get('request') && query.get('token') && !loading) {
      setLoading(true)
      Promise.all([
        dispatch(firstLoginDecrypt({encryptedText: encodeURIComponent(query.get('token')!)})),
        dispatch(firstLoginDecrypt({encryptedText: encodeURIComponent(query.get('request')!)})),
      ])
        .then((res) => {
          formik.setFieldValue('token', res[0]?.decryptedText ?? '')
          formik.setFieldValue('username', res[1]?.decryptedText ?? '')
          setLoading(false)
        })
        .catch(() => setLoading(false))
    }
  }, [])

  // console.log(formik.values)

  return (
    <FallbackOverlay active={loading}>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'AUTH.WELCOME'})}</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>
            {intl.formatMessage({id: 'AUTH.SET_INIT_PASSWORD'})}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              {intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.ERRORS'})}
            </div>
          </div>
        )}

        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <Input
            type='email'
            label={intl.formatMessage({id: 'EMAIL'})}
            placeholder={intl.formatMessage({id: 'EMAIL'})}
            touched={!!formik.touched.username}
            error={formik.errors.username || ''}
            rowInput
            disabled={true}
            value={formik.values.username}
          />
        </div>
        {/* end::Form group */}

        {/* begin::Form group Password */}
        <div className='mb-10 fv-row' data-kt-password-meter='true'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
          <input
            type='password'
            placeholder='Password confirmation'
            autoComplete='off'
            {...formik.getFieldProps('confirmPassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
              },
              {
                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
              }
            )}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.confirmPassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder'
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'SUBMIT'})}</span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'PLEASE_WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            )}
          </button>
        </div>
        <div className='d-flex flex-wrap justify-content-center mt-10'>
          <Link to='/auth/login'>
            <div id='kt_login_password_reset_form_cancel_button'>
              {intl.formatMessage({id: 'AUTH.GOTO_LOGIN'})}
            </div>
          </Link>
        </div>

        {/* end::Form group */}
      </form>
    </FallbackOverlay>
  )
}
