/* eslint-disable no-underscore-dangle */
import axios from 'axios'
import TokenService from '../token'
import {store} from 'redux/store'
import {logout} from 'redux/actions/auth-action'

const baseUrl = process.env.REACT_APP_API_URL

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        ApiKey: process.env.REACT_APP_API_KEY,
    },
})

instance.interceptors.request.use(
    async (config) => {
        const token = TokenService.getLocalAccessToken()
        const reToken = TokenService.getLocalRefreshToken()
        if (
            !config.url?.includes(`/Auth/Authenticate`) &&
            !config.url?.includes(`/Auth/ForgetPassword`) &&
            !config.url?.includes(`/Auth/DecryptText`) &&
            !config.url?.includes(`/Auth/GeneratePassword`) &&
            !config?.url?.includes(`/Auth/SetPassword`) &&
            !config.url?.includes(`/Auth/GetTokens`)
        ) {
            if (token) {
                const decodedReJwt = parseJwt(reToken)
                const decodedJwt = parseJwt(token)
                // const refreshTokenTimeout = new Date(+new Date() + 60000 * 4.9).getTime()
                const refreshTokenTimeout = Date.now()
                // add few minutes to the expiry time
                const tokenTimeout = new Date(+new Date() + 60000 * 2).getTime()

                // console.log('=======================')
                // console.log('refreshToken Timeout', new Date(refreshTokenTimeout))
                // console.log('token Timeout', new Date(tokenTimeout))
                // console.info('refreshToken', new Date(decodedReJwt.exp * 1000))
                // console.info('token', new Date(decodedJwt.exp * 1000))
                // console.log('refresh token is expired ', decodedReJwt.exp * 1000 < refreshTokenTimeout)
                // console.log('token is expired ', decodedJwt.exp * 1000 < tokenTimeout)
                // if refresh token is expired, logout
                if (decodedReJwt.exp * 1000 < refreshTokenTimeout) {
                    // logout
                    // toast.error('Session expired')
                    const {dispatch}: any = store // direct access to redux store.
                    dispatch(logout())
                    const CancelToken = axios.CancelToken

                    return {
                        ...config,
                        cancelToken: new CancelToken((cancel: (arg0: string) => any) =>
                            cancel('Cancel repeated request')
                        ),
                    }
                }
                // else if token is expired
                else if (decodedJwt.exp * 1000 < tokenTimeout) {
                    // refresh token
                    await refreshToken(token, reToken)
                }
                if (config?.headers) config.headers.Authorization = 'Bearer ' + token
                return config
            }
        } else return config
    },
    (error) => {
        // console.log('err', error)
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    (response) => response,
    async (err) => {
        // console.log('err2', err)

        const originalConfig = err.config

        if (
            err?.message !== 'Cancel repeated request' &&
            !originalConfig?.url?.includes(`/Auth/Authenticate`) &&
            !originalConfig?.url?.includes(`/Auth/ForgetPassword`) &&
            !originalConfig?.url?.includes(`/Auth/DecryptText`) &&
            !originalConfig?.url?.includes(`/Auth/GeneratePassword`) &&
            !originalConfig?.url?.includes(`/Auth/SetPassword`) &&
            !originalConfig?.url?.includes(`/Auth/GetTokens`)
        ) {
            // Access Token was expired
            if (err.response?.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true

                try {
                    const token = TokenService.getLocalAccessToken()
                    const reToken = TokenService.getLocalRefreshToken()
                    await refreshToken(token, reToken)

                    return await instance(originalConfig)
                } catch (_error) {
                    return Promise.reject(_error)
                }
            }
        }

        return Promise.reject(err)
    }
)

const refreshToken = async (oldToken: string, reToken: string) => {
    const data: any = await instance.post(`${baseUrl}/Auth/GetTokens`, {
        refreshToken: reToken,
        token: oldToken,
    }).then(res => res.data)

    if (data?.token && data?.refreshToken) {
        const {accessToken, refreshToken} = {accessToken: data?.token, refreshToken: data?.refreshToken}
        TokenService.updateLocalAccessToken(accessToken, refreshToken)
    }
    return data
}

export default instance

const parseJwt = (token: string) => {
    try {
        return JSON.parse(atob(token.split('.')[1]))
    } catch (e) {
        return null
    }
}
