import {IAuthAction, IAuthState} from 'interfaces'
import {SET_AUTH} from 'redux/actions/auth-action'

const initialState: IAuthState = {
  user: null,
}

const reducer = (state = initialState, action: IAuthAction) => {
  switch (action.type) {
    case SET_AUTH:
      return {user: action.payload}

    default:
      return state
  }
}

export default reducer
