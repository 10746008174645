import {toast} from 'react-toastify'
import {Dispatch} from 'redux'
import {IAllStates, IGetParams, IServices, IUsersResponse, UserModel} from 'interfaces'
import {errorMessageThrower} from 'utils/ErrorThrower'
import decodeJWT from 'jwt-decode'
import {appIntl} from '../../app/App'
import axios from 'axios'

export const SET_USERS = '[users] set'

const setUsers = (users: IUsersResponse) => ({type: SET_USERS, payload: users})

export const getUsers = (params: IGetParams) => {
  return async (
    dispatch: Dispatch,
    _getState: () => IAllStates,
    {api}: IServices
  ): Promise<UserModel[]> => {
    try {
      const users = await api.user.getAll(params)
      dispatch(setUsers(users))
      return users.data
    } catch (error) {
      const _section = appIntl().formatMessage({id: 'REDUX.USERS'})
      const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.FAIL_ALL'}, {section: _section})
      if (!axios.isCancel(error)) toast.error(_message)
      return errorMessageThrower(error)
    }
  }
}

export const getUser = (id?: number) => {
  return async (
    dispatch: Dispatch,
    getState: () => IAllStates,
    {api}: IServices
  ): Promise<UserModel | null> => {
    try {
      return await api.user.get(id ?? getState().auth?.user?.userId ?? -99)
    } catch (error) {
      const _section = appIntl().formatMessage({id: 'REDUX.USER'})
      const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.FAIL_ONE'}, {section: _section})
      if (!axios.isCancel(error)) toast.error(_message)
      return errorMessageThrower(error)
    }
  }
}

export const addUser = (user: UserModel) => {
  return async (dispatch: any, getState: () => IAllStates, {api}: IServices): Promise<void> => {
    try {
      var formData = new FormData()
      formData.append('email', user.email)
      formData.append('firstName', user.firstName)
      formData.append('lastName', user.lastName)
      formData.append('oib', user.oib)
      formData.append('reportLimit', user.reportLimit.toString())
      formData.append('companyName', user.companyName)
      formData.append('address', user.address)
      formData.append('city', user.city)
      formData.append('zipCode', user.zipCode)
      formData.append('country', user.country)
      formData.append('moduleList', user.moduleList ?? '')
      if (user.imagePath) formData.append('image', user.imagePath)

      await api.user.add(formData)
      await refreshData(dispatch, getState)

      const _section = appIntl().formatMessage({id: 'REDUX.USER'})
      const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.CREATED'}, {section: _section})
      toast.success(_message)
    } catch (error) {
      return errorMessageThrower(error)
    }
  }
}

export const updateUser = (user: UserModel, shouldRefreshData: boolean = false) => {
  return async (dispatch: any, getState: () => IAllStates, {api}: IServices): Promise<void> => {
    try {
      var formData = new FormData()
      formData.append('id', user.id!.toString())
      formData.append('email', user.email)
      formData.append('firstName', user.firstName)
      formData.append('lastName', user.lastName)
      formData.append('oib', user.oib)
      formData.append('reportLimit', user.reportLimit.toString())
      formData.append('companyName', user.companyName)
      formData.append('address', user.address)
      formData.append('city', user.city)
      formData.append('zipCode', user.zipCode)
      formData.append('country', user.country)
      formData.append('moduleList', user.moduleList)
      if (user.imagePath) formData.append('image', user.imagePath)

      await api.user.update(user.id, formData)
      if (shouldRefreshData) await refreshData(dispatch, getState)

      const _section = appIntl().formatMessage({id: 'REDUX.USER'})
      const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.UPDATED'}, {section: _section})
      toast.success(_message)
    } catch (error) {
      return errorMessageThrower(error)
    }
  }
}

export const toggleUserActiveState = (username: string, setEnable: boolean) => {
  return async (
    dispatch: Dispatch,
    getState: () => IAllStates,
    {api}: IServices
  ): Promise<void> => {
    const _section = appIntl().formatMessage({id: 'REDUX.USER'})
    try {
      if (setEnable) await api.user.enableUser(username)
      else await api.user.disableUser(username)

      await refreshData(dispatch, getState)

      const _message = appIntl().formatMessage(
        {id: setEnable ? 'REDUX.GENERAL.ACTIVATE' : 'REDUX.GENERAL.DEACTIVATE'},
        {section: _section}
      )
      toast.success(_message)
    } catch (error) {
      const _message = appIntl().formatMessage(
        {id: 'REDUX.GENERAL.DELETE.FAILED'},
        {section: _section}
      )
      if (!axios.isCancel(error)) toast.error(_message)
      return errorMessageThrower(error)
    }
  }
}

export const deleteUser = (id: number) => {
  return async (
    dispatch: Dispatch,
    getState: () => IAllStates,
    {api}: IServices
  ): Promise<void> => {
    const _section = appIntl().formatMessage({id: 'REDUX.USER'})
    try {
      await api.user.delete(id)
      await refreshData(dispatch, getState)

      const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.DELETED'}, {section: _section})
      toast.success(_message)
    } catch (error) {
      const _message = appIntl().formatMessage(
        {id: 'REDUX.GENERAL.DELETE.FAILED'},
        {section: _section}
      )
      if (!axios.isCancel(error)) toast.error(_message)
      return errorMessageThrower(error)
    }
  }
}

const refreshData = async (dispatch: any, getState: () => IAllStates) => {
  const {pageNumber, pageSize} = getState().user.users
  await dispatch(getUsers({PageNumber: pageNumber, PageSize: pageSize}))
}

export const getSearchedUsers = (search: string, params: IGetParams) => {
  return async (
    dispatch: Dispatch,
    _getState: () => IAllStates,
    {api}: IServices
  ): Promise<UserModel[]> => {
    try {
      const users = await api.user.searchUsers(search, params.PageNumber, params.PageSize)
      dispatch(setUsers(users))
      return users.data
    } catch (error) {
      const _section = appIntl().formatMessage({id: 'REDUX.USERS'})
      const _message = appIntl().formatMessage({id: 'REDUX.GENERAL.FAIL_ALL'}, {section: _section})
      if (!axios.isCancel(error)) toast.error(_message)
      return errorMessageThrower(error)
    }
  }
}
