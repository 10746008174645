/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '_metronic/helpers'
import {useIntl} from 'react-intl'
import {FirstLogin} from '../firstLogin'
import {SetPassword} from '../setPassword'

export function AuthPage() {
    const intl = useIntl()
    useEffect(() => {
        document.body.classList.add('bg-white')
        return () => {
            document.body.classList.remove('bg-white')
        }
    }, [])

    return (
        <div
            className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/login.png')})`,
            }}
        >
            {/* begin::Content */}
            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                {/* begin::Wrapper */}
                <div className='w-lg-550px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
                    <Switch>
                        <Route path='/auth/login' component={Login}/>
                        <Route path='/auth/registration' component={Registration}/>
                        <Route path='/auth/forgot-password' component={ForgotPassword}/>
                        <Route path='/auth/userfirstlogin' component={FirstLogin}/>
                        <Route path='/auth/setpassword' component={SetPassword}/>
                        <Redirect from='/auth' exact={true} to='/auth/login'/>
                        <Redirect to='/auth/login'/>
                    </Switch>
                </div>
                {/* end::Wrapper */}
            </div>
            {/* end::Content */}
            {/* begin::Footer */}
            <div className='d-flex flex-center flex-column-auto p-10'>
                <div className='d-flex align-items-center flex-column flex-md-row fw-bold fs-6'>
                    {/*<div className='text-muted text-hover-primary px-2'>{`${intl.formatMessage({*/}
                    {/*  id: 'FOOTER_COPYRIGHT',*/}
                    {/*})} ${new Date().getFullYear()}`}</div>div*/}
                    {/*<span className='text-muted px-2 d-none d-md-block'>/</span>*/}
                    <a
                        href='https://www.biljeske.com/uvjeti-koristenja'
                        className='text-muted text-hover-primary px-2'
                        style={{marginLeft: '5px'}}
                    >
                        {intl.formatMessage({id: 'FOOTER_LINK_AGREEMENT'})}
                    </a>
                    <span className='text-muted px-2 d-none d-md-block'>/</span>

                    <a
                        href='https://www.biljeske.com/kontakt/'
                        className='text-muted text-hover-primary px-2'
                        style={{marginLeft: '5px'}}
                    >
                        {intl.formatMessage({id: 'FOOTER_LINK_TERMS'})}
                    </a>
                    {/*<span className='text-muted px-2 d-none d-md-block'>/</span>*/}
                    {/*<Link*/}
                    {/*  to='/support'*/}
                    {/*  className='text-muted text-hover-primary px-2'*/}
                    {/*  style={{marginLeft: '5px'}}*/}
                    {/*>*/}
                    {/*  {intl.formatMessage({id: 'FOOTER_LINK_CONTACT'})}*/}
                    {/*</Link>*/}
                </div>
            </div>
            {/* end::Footer */}
        </div>
    )
}
