import {IBannerAction, IFaqAction, ISettingsState} from 'interfaces'
import {SET_BANNER, SET_FAQ} from 'redux/actions/settings-action'

const initialState: ISettingsState = {
  banner: {
    data: [],
    totalRecordCount: 0,
    pageNumber: 1,
    pageSize: 10,
  },
  faq: {
    data: [],
    totalRecordCount: 0,
    pageNumber: 1,
    pageSize: 10,
  },
}

const reducer = (state = initialState, action: IBannerAction | IFaqAction) => {
  switch (action.type) {
    case SET_BANNER:
      return {...state, banner: action.payload}
    case SET_FAQ:
      return {...state, faq: action.payload}

    default:
      return state
  }
}

export default reducer
