/* eslint-disable */
import {IGetParams, ReportModel} from 'interfaces'
import axios from 'infrastructure/services/axios/interceptor'

const baseUrl = process.env.REACT_APP_API_URL

export default {
    getReports: (params: IGetParams) => axios.get(`${baseUrl}/Report`, {params}).then(res => res.data),

    getReport: (id: number) => axios.get(`${baseUrl}/Report/${id}`).then(res => res.data),

    addReport: (data: FormData) => axios.post(`${baseUrl}/Report`, data).then(res => res.data),

    updateReport: (id: number, data: FormData) => axios.put(`${baseUrl}/Report/${id}`, data).then(res => res.data),

    deleteReport: (id: number) => axios.delete(`${baseUrl}/Report/${id}`).then(res => res.data),

    getJSONFromTemplate: (data: FormData) => axios.post(`${baseUrl}/Report/GetJSONForTemplate`, data).then(res => res.data),

    getSpecies: () => axios.get(`${baseUrl}/Report/GetSpecies`).then(res => res.data),

    createNoteDecisions: (data: FormData) =>
        axios.post(`${baseUrl}/NotesDecisions/CreateNotesDecisions`, data).then(res => res.data),

    downloadNoteDecisions: (userId: number, moduleId: number) =>
        axios.post(
            `${baseUrl}/NotesDecisions/DownloadNotesDecisions`,
            {userId, moduleId},
            {responseType: 'blob'}
        ),
}
