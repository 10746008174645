import { combineReducers } from "redux";

import auth from "./auth";
import settings from "./settings";
import module from "./module";
import report from "./report";
import user from "./user";

export default combineReducers({
  auth,
  settings,
  module,
  report,
  user
});
