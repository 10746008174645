/* eslint-disable */
import auth from "./auth";
import settings from "./settings";
import module from "./module";
import report from "./report";
import support from "./support";
import user from "./user";

export default {
  auth,
  settings,
  module,
  report,
  support,
  user
};
